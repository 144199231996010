import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, ...rest }) => {
  const internal = /^[/#](?!\/)/.test(to)
  if (internal || !to) {
    return (
      <GatsbyLink
        to={to.replace(/\/?$/, '/')}
        // eslint-disable-next-line
        {...rest}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={to} rel="noopener noreferrer" target="_blank" {...rest}>
      {children}
    </a>
  )
}
export default Link
