import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { copyright } from './style.module.scss'

const Copyright = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        copywriteYear: buildTime(formatString: "YYYY")
      }
    }
  `)

  return (
    <p className={copyright}>
      Copyright &copy; 2020 - {data.site.copywriteYear}{' '}
      <a
        href="https://discoverleeds.co.uk/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Discover Leeds
      </a>
    </p>
  )
}

export default Copyright
