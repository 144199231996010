import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { header, imageStyle, titleStyle } from './style.module.scss'

const JSONContent = require(`../../content/${process.env.GATSBY_SITEVERSION}/config.json`)

const Hero = () => {
  const data = useStaticQuery(graphql`
    query Hero {
      large: file(relativePath: { eq: "hero.jpg" }) {
        id
        root
        relativePath
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      mob: file(relativePath: { eq: "hero-mob.jpg" }) {
        id
        root
        relativePath
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const images = withArtDirection(getImage(data.large), [
    {
      media: '(max-width: 1024px)',
      image: getImage(data.mob),
    },
  ])

  return (
    <section className={header} id="header">
      <h1
        className={titleStyle}
        dangerouslySetInnerHTML={{ __html: JSONContent.hero.title }}
      />
      <GatsbyImage
        alt={JSONContent.hero.imageAlt}
        image={images}
        loading="eager"
        className={imageStyle}
        style={{ position: 'absolute' }}
      />
    </section>
  )
}

export default Hero
