import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import Layout from '../containers/Layout'
import Container from '../containers/Container'
import Link from '../atoms/Link'

import Copyright from '../components/Copyright'
import Hero from '../components/Hero'
import Seo from '../components/Seo'

const JSONConfig = require(`../content/${process.env.GATSBY_SITEVERSION}/config.json`)

const Homepage = ({ data }) => {
  useEffect(() => {
    const tocLinks = document.querySelectorAll('.table-of-contents li a')

    tocLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        const linkText = event.target.innerText
        if (window.plausible) {
          window.plausible('QuickLink', {
            props: { name: linkText },
          })
        }
      })
    })
  }, [])

  return (
    <Layout>
      <Seo title={JSONConfig.prettyName} description={JSONConfig.description} />
      <Hero />
      <Container>
        <p>
          <span>Last Updated: </span>
          <time dateTime={data.site.buildTime} itemProp="dateModified">
            {data.site.humanBuildTime}
          </time>
        </p>
      </Container>
      <br />
      <MDXProvider
        components={{
          h2: ({ children, ...rest }) => (
            <h2 {...rest} itemProp="name">
              {children}
            </h2>
          ),
          h3: ({ children, ...rest }) => (
            <h3 {...rest} itemProp="name">
              {children}
            </h3>
          ),
          h4: ({ children, ...rest }) => (
            <h4 {...rest} itemProp="name">
              {children}
            </h4>
          ),
          Link: ({ children, ...rest }) => <Link {...rest}>{children}</Link>,
        }}
      >
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </MDXProvider>
      <Copyright />
    </Layout>
  )
}

export const postQuery = graphql`
  query {
    site {
      humanBuildTime: buildTime(formatString: "Do MMMM YYYY")
      buildTime
    }
    mdx {
      body
    }
  }
`

export default Homepage
