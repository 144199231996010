import React, { useEffect, useRef } from 'react'

import './style.scss'

const Layout = ({ children }) => {
  const scrolled = useRef(null)

  useEffect(() => {
    const headID = document.getElementsByTagName('head')[0]

    document.addEventListener('scroll', function (e) {
      if (!scrolled.current) {
        scrolled.current = true

        // Google Ads
        const gaScript = document.createElement('script')
        gaScript.async = true
        gaScript['data-ad-client'] = 'ca-pub-9412125337838543'
        gaScript.src =
          'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
        gaScript.type = 'text/javascript'
        gaScript.crossorigin = 'anonymous'
        headID.appendChild(gaScript)
      }
    })
  })

  return <div>{children}</div>
}

export default Layout
