import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

const Seo = ({ title, description }) => {
  const data = useStaticQuery(graphql`
    query Seo {
      file(relativePath: { eq: "meta.jpg" }) {
        id
        root
        relativePath
        facebook: childImageSharp {
          gatsbyImageData(layout: FIXED, width: 1024, height: 512)
        }
        twitter: childImageSharp {
          gatsbyImageData(layout: FIXED, width: 1200, height: 630)
        }
      }
    }
  `)
  return (
    <Helmet>
      <title>{title}</title>
      <meta content={description} name="description" />

      <meta content={getSrc(data.file.facebook)} name="image" />

      {/* OpenGraph tags */}
      <meta content="https://meanwoodvalleytrail.co.uk" property="og:url" />
      <meta content={title} name="twitter:title" />
      <meta content={title} name="title" property="og:title" />

      <meta
        content={description}
        name="description"
        property="og:description"
      />

      <meta
        content={`https://meanwoodvalleytrail.co.uk${getSrc(
          data.file.facebook
        )}`}
        name="image"
        property="og:image"
      />

      <meta content={description} name="twitter:description" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta
        content={`https://meanwoodvalleytrail.co.uk${getSrc(
          data.file.twitter
        )}`}
        name="twitter:image"
      />
    </Helmet>
  )
}

export default Seo
